.registration-page {
  display: grid;
  justify-content: center;
  text-align: center;
  margin-bottom: 140px;

  &_main-container {
    align-items: center;
    justify-content: center;

    @include tablet-s {
      width: 400px;
    }

    @include mobile {
      width: 321px;
    }
  }

  &_input-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: "center";
    margin-top: 73px;
  }
}

.register-title {
  @extend .order-flow-title;
}

.register-subtitle {
  @extend .order-flow-subtitle;
}

.register-text {
  @extend .order-flow-text;
}

.register-link {
  @extend .order-flow-link;
}

.login-link {
  text-decoration: none;
}
