.order-fast-button {
  width: 260px;
  height: 265px;
  background-color: $backgroundGray;
  border: none;
  border-radius: 9px;
  margin-inline: 15px;

  @include tablet {
    width: 200px;
    height: 200px;
    margin-inline: 10px;
  }

  @include tablet-s {
    margin-bottom: 20px;
    width: 155px;
    height: 155px;
  }

  @include mobile {
    margin-bottom: 20px;
    width: 40%;
    height: 40%;
  }

  &_image-container {
    width: 100%;
    height: 150px;
    margin-top: 35px;

    @include tablet {
      height: 100px;
      margin-top: 20px;
    }

    @include tablet-s {
      height: 80px;
      margin-top: 15px;
    }

    @include mobile {
      height: 50px;
      margin-top: 15px;
    }
  }

  &_image {
    height: 100%;
  }

  &_title {
    font-family: "Poppins-Regular";
    font-size: 20px;
    color: $subtitleBlack;
    margin-bottom: 19px;
    font-weight: 500;

    @include tablet {
      font-size: 15px;
    }
  }
}
