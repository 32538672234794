.next-level-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-align: left;

  .text-container {
    max-width: 214px;
    font-family: "Poppins-Regular";
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    color: $subtitleBlack;
  }
}

.inner-svg {
  position: absolute;
  width: 59px;
  height: 58px;

  path {
    fill: $svgFillRed !important;
  }
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
