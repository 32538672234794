.product-list-page-container {
  margin-top: 40px;
  margin-inline: 88px;
  margin-bottom: 90px;

  .product-list-page-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 100px;

    @include tablet-m {
      flex-direction: column;
      margin-bottom: 0px;
    }
  }

  .category-image {
    max-width: 400px;
    max-height: 357px;
    object-fit: contain;

    @include tablet {
      max-width: 200px;
    }
  }

  .product-list-container {
    display: flex;
    justify-content: space-between;    

    .subcategories-container {
      width: 331px;      
    }    

    @include tablet-m {
      flex-direction: column-reverse;
      align-items: center;       
    }

    .search-filter-products-container {
      display: flex;
      flex-direction: column;      
    }

    .search-products-input {
      @extend .input-text;
      width: 321px;
      height: 66px;
      padding-left: 16px;
      margin-bottom: 0px;
      font-size: 20px;
      border: 0.72px solid $inputBorderGray;
      border-radius: 6.3px;      
    
      @include tablet-m {
        margin-top: 67px;
        display: none
      }

      @include mobile {
        width: 221px;
      }
    }

    .search-icon {
      margin-left: 300px;
      position: absolute;

      @include tablet-m {
        margin-top: 67px;
        display: none;
      }           
    }
  }
}

.products {
  display: flex;
  flex-wrap: wrap;
  margin-right: 32px;

  &_subtitle {
    width: 511px;
    height: 175px;
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 66px;
    text-transform: uppercase;

    @include tablet-m {
      text-align: center;
      width: auto;
      height: auto;
    }
  }

  @include desktop-s {    
    margin-right: 16px;
    padding: 0 5px;
    margin-top: 0px;
    grid-gap: 50px;
  }  

  @include tablet-m {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0px;
    margin-top: 67px;
  }
}

.subcategory-title-text {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 22.4px;
  line-height: 33.6px;
  text-align: left;
  margin-top: 52px;
  margin-bottom: 29px;
  color: #353535;
}

#product-subcategory-button {
  border: none;
  background-color: white;
  text-align: left;
  padding-left: 20px;        
  cursor: pointer;
  padding: 0;
  
  &_clicked {
    @extend #product-subcategory-button;          
  }

  &_text {
    @extend .order-flow-subtitle;

    &_clicked {
      @extend .order-flow-subtitle;
      color: $red;
    }
  }
}
