.dashboard-button-container {
  position: relative;
  width: calc(25% - 15px);
  max-height: 320px;
  background-color: $backgroundWhite;
  border-radius: 9px;
  box-sizing: border-box;
  text-align: left;

  @include tablet-m {
    width: 100%;
    margin-bottom: 20px;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  .dashboard-button-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding-top: 49px;
    padding-left: 49px;

    .dashboard-button-font {
      font-family: "Poppins-Regular";      
      font-size: 20px;
      line-height: 34px;
      margin-top: 31px;
      color: $subtitleBlack;                     

      @include desktop-m {
        margin-top: 0px;
        font-size: 14px;
      }

      @include tablet {
        font-size: 12px;
      }

      @include tablet-m {
        font-size: 20px;
        margin-top: 31px;
      }
    }

    .view-all-btn {
      font-family: "Poppins-Regular";
      font-size: 20px;
      line-height: 20px;
      margin-top: 20px;
      margin-bottom: 44px;
      text-decoration: underline;
      color: $subtitleBlack;
      background-color: transparent;
      border: none;
      text-align: left;  
      cursor: pointer;    

      @include desktop-m {
        font-size: 14px;
      }

      @include tablet {
        font-size: 12px;
      }
    }
  }
}

.active-container {
  background-color: #1d1d1d;

  .dashboard-button-content {
    .dashboard-button-font {
      color: $white;
    }

    .view-all-btn {
      color: $white;
    }
  }
}
