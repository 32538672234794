.category-button {
  width: 100%;
  cursor: pointer;
  height: 294px;
  border: none;
  border-radius: 9px;
  padding: 0px;
  position: relative;

  @include tablet {
    height: 194px;
  }

  &_title {
    font-family: "Poppins-Regular";
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: $white;
    text-align: left;
    padding-left: 26px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
