.time-modal-container {
  width: 463px;
  height: 240px;
  border-radius: 17px;
  box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.159845),
    0px 17px 36px rgba(0, 0, 0, 0.03994);
  border-radius: 18px;

  @include tablet-s {
    width: auto;
  }
}

.time-modal-content {
  width: 100%;
  height: 65%;
  display: flex;
  justify-content: center;
  align-items: center;

  .time-modal-clock {
    width: 205px;
    height: 72px;
    border: 0.8px solid #c7c7c7;
    box-sizing: border-box;
    border-radius: 8.8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 43px;
    line-height: 65px;
  }
}

.time-modal-set-time {
  border: none;
  font-family: "Poppins-Regular";
  font-weight: 400;
  font-size: 43px;
  line-height: 65px;
  width: 70px;
  text-align: center;

  &:focus {
    outline: none;
  }
}

.set-hour {
  transform: translateX(15px);
}

.time-confirm-button-container {
  padding-bottom: 20px;
  display: flex;
  justify-content: center;

  .shop-location-time-confirm-button {
    width: 205px;
    height: 61px;
    background: #191919;
    border-radius: 7px;
    color: #ffffff;
    font-family: "Poppins-Regular";
    font-size: 17px;
    font-weight: 400;
    line-height: 25.5px;
    text-transform: uppercase;
    cursor: pointer;

    @include tablet-s {
      width: 125px;
    }
  }
}

.cancel-button {
  width: 205px;
  height: 61px;
  background: #ffffff;
  border-radius: 7px;
  color: #000000;
  font-family: "Poppins-Regular";
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-transform: uppercase;
  margin-right: 10px;
  cursor: pointer;

  @include tablet-s {
    width: 125px;
  }
}
