.popular-choices-subtitle {
  @extend .subtitle;
  margin-top: 137px;
  margin-bottom: 57px;

  @include tablet {
    margin-top: 70px;
  }

  @include tablet-s {
    margin-top: 60px;
  }
}

.popular-choices-render-container {
  display: flex;
  justify-content: center;

  @include tablet-m {
    flex-wrap: wrap;
   }
}
