.search-container {
  @include tablet {
    width: 70%;
  }

  @include tablet-m {
    display: none;
  }

  .search-bar {
    width: 100%;
    height: 62px;

    border: 1px solid #9e9e9e;
    border-radius: 9px;

    font-size: 22px;
    line-height: 33px;

    padding-left: 17px;

    margin-left: 17px;

    //color: $subtitleBlack;
  }

  .search-icon {
    position: relative;
    bottom: 45px;
    left: 100%;
  }
}
