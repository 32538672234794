.article-with-desc-container {
  width: 50%;
  padding: 0 24px 24px 24px;

  margin-bottom: 68px;

  box-sizing: border-box;

  @include tablet-m {
    width: 100%;
  }

  .article-image {
    width: 100%;
    //max-width: 424px;
    height: 400px;

    border-radius: 15px;
  }

  .text-container {
    width: 100%;
    max-width: 410px;
  }

  .read-more-btn {
    border: 1px solid $lightGray;
    border-radius: 5px;

    height: 50px;
    width: 165px;

    background-color: transparent;

    font-size: 20px;
    line-height: 100%;

    &:hover {
      cursor: pointer;

      border: 2px solid $gray;
    }
  }
}
