.communityPage {
  width: 100%;

  &-title-search-container {
    display: flex;
    flex-direction: row;
    padding: 90px 90px 40px 90px;
    justify-content: space-between;

    @include tablet-m {
      padding: 90px 50px 40px 50px;
    }

    .search-container {
      margin-top: 13px;
      margin-right: 0px;

      @include tablet {
        display: none !important;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: row;

    .all-reviews-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include mobile {
      padding: 0px;
    }
  }
}

.communityPage-title {
  margin: 0;
}

.communityPage-subtitle {
  margin-top: 6px;
}

.communityPage-title-search-container {
  width: 75%;
  margin-right: 0;
}
