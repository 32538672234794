.order-details-container-checkout {
  margin-top: 0px;

  @include tablet-s {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.order-details-title {
  @extend .input-text;
  width: 300px;
  height: 23px;
  font-size: 15px;
  line-height: 22px;
  text-align: left;
  text-transform: uppercase;
  color: #313131;

  @include tablet-m {
    text-align: center;
    width: auto;
  }
}
