.order-details-container {
  display: flex;
  flex-direction: column;
  margin-top: 55px;

  .details-container {
    display: flex;
    flex-direction: row;
    padding: 11px 0 0 0;
    margin-bottom: 36px;

    .details-text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 11px;
    }

    .logo-small {
      max-width: 72px;
    }
  }

  .product-span {
    margin-left: 18px;
  }

  .flavour-span {
    margin-left: 24px;
  }
}

.details-products-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
