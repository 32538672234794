.title-with-description-container {
  width: 50%;
  height: 177px;

  @include desktop-s {
    width: 100% !important;
  }

  .title-with-description-title {
    font-family: "Poppins-Regular";
    font-weight: 600;
    font-size: 40px;
    line-height: 150%;

    color: $subtitleBlack;

    @include desktop-s {
      font-size: 35px;
    }

    @include tablet-s {
      font-size: 30px;
    }

    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .title-with-description-subtitle {
    font-family: "Poppins-Regular";
    font-weight: 300;
    font-size: 26px;
    line-height: 35px;

    color: $subtitleBlack;

    @include desktop-s {
      font-size: 20px;
    }

    @include tablet-s {
      font-size: 16px;
    }
  }
}

.title-with-description-container-community-page {
  @extend .title-with-description-container;
  width: 100%;
  font-weight: 500;
}
