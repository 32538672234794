.loading-spinner {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;    
    background-color: white;    
}

#spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

