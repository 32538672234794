.cover-image {
  background-repeat: no-repeat;
  background-size: cover;
  max-height: $coverImageHeight;
  margin-bottom: 0px;
  width: 100%;
  align-self: center;
}

.center-container {
  display: flex;
  justify-content: center;
  padding: 92px 162px 92px 153px;

  @include desktop-m {
    padding: 62px 132px 62px 133px;
  }

  @include tablet {
    padding: 32px 102px 32px 103px;
  }

  @include tablet-s {
    padding: 10px 10px 10px 10px;
  }

  .article-data-container {
    max-width: 724px;
    width: 100%;

    h2 {
      text-transform: capitalize;
      margin-bottom: 23px;
    }

    h5 {
      margin-top: 0;
    }
  }
}

.knowledge-container {
  text-align: center;
  margin-bottom: 100px;
}
