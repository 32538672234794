.onboarding-coffee-flavour-button {
    padding: 20px 30px;
    font-family: "Poppins-Regular";
    font-size: 16px;
    line-height: 13px;
    color: #434343;
    border: none;
    border-radius: 7.29px;
    background: #e8e8e8;
    margin-right: 20px;
    margin-bottom: 18px;
    cursor: pointer;
    
    &_clicked {
        padding: 20px 30px;
        font-family: "Poppins-Regular";
        font-size: 16px;
        line-height: 13px;
        color: #434343;
        border: 0.5px solid $red;
        border-radius: 7.29px;
        background: white;
        margin-right: 20px;
        margin-bottom: 18px;
        cursor: pointer;
    }
  }