.info-text {
    display: flex;
    flex-direction: column;    
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &_text {
        @extend .order-flow-title;
        text-align: center;
        margin: 0;
    }
}