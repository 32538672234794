.single-product-page {
  display: flex;
  margin-top: 83px;
  padding-bottom: 127px;
  justify-content: center;

  @include desktop-s {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.single-product-image-container {  
  margin-right: 83px;

  @include desktop-s {
    margin-right: 0;
  }  

  img {
    border-radius: 8px;
  }
}

.single-product-image {
  width: 516px;
  height: 611px;

  @include tablet-m {
    width: 258px;
    height: 305.5px;
  }
}

.product-order-options-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include desktop-s {
    align-items: center;
  }
}

.single-product-page-description {
  font-family: "Poppins-Regular";
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
}

.single-product-name {
  font-family: "Poppins-Regular";
  font-weight: 500;
  font-size: 34px;
  line-height: 69px;
  color: #353535;
  margin-top: 0;

  @include tablet-m {
    font-size: 17px;
  }
}

.single-product-price {
  font-family: "Poppins-Regular";
  font-weight: 400;
  font-size: 31px;
  line-height: 46.5px;
  color: #353535;
  margin: 0;

  @include desktop-s {
    text-align: center;
  }

  @include tablet-m {
    font-size: 15.5px;
  }
}

.single-product-size-container {
  margin-top: 30px;

  @include desktop-s {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.single-page-product-info {
  margin-top: 20px;
  font-family: "Poppins-Regular";
  font-size: 15px;
  line-height: 22.5px;
  color: #494949;
}

.other-categories-container {
  margin-bottom: 74px;
}
