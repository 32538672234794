.shop-location-modal-container {
  position: relative;
  bottom: 90px;
  background: #f9f9f9;
  border-radius: 17px;
  width: 463px;
  height: 382px;
  overflow: hidden;
  box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.159845),
    0px 17px 36px rgba(0, 0, 0, 0.03994);

  @include tablet-s {
    width: auto;
  }
}

.shop-location-modal-content {
  height: 65%;
  font-family: "Poppins-Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 15px;
  overflow-y: scroll;
  scrollbar-width: none;

  .shop-location-modal-description {
    width: 84%;
    display: flex;
    align-items: center;
    color: #000000;
    border-radius: 7px;
    border: none;
    padding: 20px;
    padding-left: 50px;
    font-family: "Poppins-Regular";
    background-color: #e8e8e8;

    @include tablet-s {
      width: 77%;
    }
  }

  .shop-location-modal-description::placeholder {
    color: #000000;
  }

  .shop-location-modal-option {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    color: #404040;
    background: #ffffff;
    border-radius: 7px;
    border: none;
    padding: 20px;
    font-family: "Poppins-Regular";
    margin: 10px 0;
    cursor: pointer;
  }
}

.shop-location-modal-content::-webkit-scrollbar {
  display: none;
}

.location-confirm-button-container {
  display: flex;
  justify-content: end;
  padding: 19px;  

  @include tablet-s {
    justify-content: center;
  }

  .shop-location-time-confirm-button {
    width: 205px;
    height: 61px;
    background: #191919;
    border-radius: 7px;
    color: #ffffff;
    font-family: "Poppins-Regular";
    font-size: 17px;
    font-weight: 400;
    line-height: 25.5px;
    text-transform: uppercase;
    
  }
}
