.dashboard-container {
  display: flex;
  flex-direction: column;
  margin-top: 51px;

  .dashboard-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @include tablet-m {
      display: block;
    }
  }

  .order-buttons-container {    
    justify-content: space-evenly;
    margin-top: 48px;
    z-index: 1;
    
    .order-button-status-history {
      margin-right: 10px;
      width: 220px;
      height: 61px;
      border: 1px solid #adadad;
      border-radius: 10px;
      font-family: "Poppins-Regular";
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      color: #1f1f1f;
      background-color: white;
      cursor: pointer;

      @include tablet-s {
        margin-bottom: 10px;
      }

      &_clicked {
        @extend .order-button-status-history;
        border: 1px solid $red;
      }
    }
  }

  .dashboard-data {
    width: 100%;

    .card-row-container {
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 64px;
    }

    .order-bars-container {
      margin-top: 64px;
    }

    .credit-card-row-container {
      display: flex;
      flex-direction: column; 
      width: 100%;    
      align-items: center;
      margin-top: 64px;
    }
  }
}
