.progress-overview-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 9px;
  padding: 44px 72px 35px 40px;
  box-sizing: border-box;

  @include tablet {
    display: block;
  }

  .coffeebeans-data {
    width: 50%;

    @include tablet {
      width: 100%;
    }

    .first-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-family: "Poppins-Regular";
      font-size: 20px;
      line-height: 30px;
      text-transform: uppercase;
      color: $subtitleBlack;
    }

    .second-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 17px;
      font-family: "Poppins-Regular";
      font-weight: 600;
      font-size: 26px;
      line-height: 39px;
    }

    .fourth-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 18px;
      font-family: "Poppins-Regular";
      font-size: 18px;
      line-height: 27px;
      text-transform: uppercase;
      color: $subtitleBlack;
    }

    .coffeebeans-data-text {
      @include mobile {
        text-align: left;
      }
    }
  }

  .next-level-container {
    width: 45%;

    @include tablet {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    @include desktop-s {
      padding-top: 20px;
    }
  }
}
