.community-page-main-container {
  padding: 75px 153px 0 153px;

  @include desktop-m {
    padding: 55px 133px 0 133px;
  }

  @include desktop-s {
    padding: 35px 113px 0 113px;
  }

  @include tablet-m {
    display: flex;
    flex-direction: column;
  }
  
  @include tablet-s {
    align-items: center;
  }  

  .top-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 64px;

    @include tablet {      
      display: flex;
      flex-direction: column;      
    }

    @include tablet-m {
      margin-bottom: 0;
    }

    .text-container {
      .title-with-description-container {
        height: 70px;
      }
    }

    .image-container {
      width: 479px;
      height: 556px;

      @include tablet {
        margin-top: 30px;
      }

      @include tablet-m {
        width: 239.5px;
        height: 278px;  
      }
    }
  }

  hr {
    margin-bottom: 49px;
    color: #979797;

    @include tablet-s {
      display: none;
    }
  }

  .rating-container {
    @include tablet-s{
      display: none;
    }
  }

  .review-details-container {
    margin-top: 54px;
  }

  .photos-section-container {
    margin-top: 60px;

    @include tablet-s {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .review-title {
      font-family: "Poppins-Regular";
      font-weight: 500;
      font-size: 33px;
      line-height: 33px;
      margin-top: 0px;
      margin-bottom: 42px;
    }

    .images-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;      
      margin-top: 48px;
      margin-bottom: 157px;

      @include mobile {
        margin-top: 0;
        margin-bottom: 57px;
      }

      .image-container {                
        height: 307px;
        width: 372px;
        margin-right: 10px;
        margin-bottom: 15px;                

        @include mobile {
          width: 232px;
          margin-right: 0;
        }
      }
    }
  }
}
