.read-more-btn {
  border: 1px solid $lightGray;
  border-radius: 5px;
  height: 50px;
  width: 165px;
  background-color: transparent;
  font-size: 20px;
  line-height: 100%;
  margin-top: 20px;

  &:hover {
    cursor: pointer;
    border: 2px solid $gray;
  }
}
