.buy-quantity-and-add-to-cart-container {
  margin-top: 30px;
  display: flex;

  @include desktop-m {
    display: block;
  }

  .quantity-container {
    width: 144px;
    height: 73px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins-Regular";
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    border-radius: 10px;
    border: 1px solid #979797;
    margin-right: 10px;

    @include desktop-m {
      margin-bottom: 20px;
    }

    .single-product-quantity-button {
      display: flex;
      align-items: center;
      border: none;
      background: transparent;
      width: 20px;
      height: 30px;
      cursor: pointer;
    }

    .single-product-counter {
      width: 40px;
      display: flex;
      justify-content: center;
    }
  }

  .add-to-cart-and-buy-container {
    .add-buy-product-button {
      cursor: pointer;
      width: 150px;
      height: 73px;
      background: #e23036;
      border-radius: 10px;
      border: none;
      font-family: "Poppins-Regular";
      font-weight: 500;
      color: #ffffff;
      margin-right: 10px;
    }
  }
}
