.landing-page-main-container {
  text-align: center;
}

.landing-page-padding-template {
  @include tablet {
    padding-left: 36px;
    padding-right: 36px;
  }

  @include tablet-m {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.footer-background {
  width: 100%;
  height: 366px;
  background-image: url("../../assets/images/landingPage/footer_image.png");
  background-size: cover;
  background-position: center center;

  @include tablet {
    max-height: 257px;
    max-width: 100%;
    background-image: url("../../assets/images/landingPage/footer_tablet.png");
  }

  @include mobile {
    max-height: 100%;
    max-width: 100%;
    background-image: url("../../assets/images/landingPage/footer_mobile.png");
  }
}

.non-logged-user-order-map{
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 20px;
  margin-bottom: 50px;
  width: 25%;

  @include desktop-m {
    width: 33%;
  }

  @include tablet-m {    
    margin-bottom: 25px;
  }

  @include tablet-s {
    width: 50%;
  }

  @include mobile {
    width: 60%;
  }
}
