.reward-page{
    background: $white;

    display: flex;

    justify-content: center;

    .reward-text-container{
        display: flex;
        flex-direction: column;

        justify-content: center;
        
        background-image: url('../../assets/images/rewardPage/coffee-beans-earned-image.png');
        background-size: contain;
        background-repeat: no-repeat;

        max-width: 776px;
        width: 100%;
        min-height: 494px;

        text-align: center;

        .points-container{
            margin: 24px 0 32px 0;

            svg{
                margin-right: 10px;
            }
        }
    }
}