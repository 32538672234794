.additional-order-information-container {
    display: flex;
    flex-direction: column;

    @include tablet-m {
        justify-items: center;
    }

    &_title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_input-container {
        display: flex;
        justify-content: space-around;
        border: 1px;
    }

    .css-1s2u09g-control {
        border: 1px;
        border-color: black;
        margin-top: 13px;
        cursor: pointer;
    }
}

@media screen and (max-width: 900px) {
    .additional-order-information-container {
        display: flex;
        flex-direction: column;
    }

}
@media screen and (max-width: 768px) {
    .input-payment-card {
        width: 98%;
    }
}