.credit-card {
    display: flex;    
    justify-content: space-between;
    width: 70%;
    align-items: center;
    margin-bottom: 24px;
    border: 1.2px solid $inputBorderGray;
    border-radius: 9px;
    padding-inline: 16px;

    @include tablet-m {
        width: 90%;
    }

    &_credit-card-name {
        text-transform: capitalize;
        font-family: "Poppins-Regular";
        font-weight: 600;
        font-size: 26px;
        line-height: 35px;
        overflow: hidden;  
        white-space: nowrap;
        text-overflow: ellipsis;
        color: $subtitleBlack;
    }

    &_credit-card-number {
        font-family: "Poppins-Regular";
    }

    &_delete-button {
        border: none;
        background-color: transparent;
        cursor: pointer;        

        &_icon {
            width: 25px;
            height: 25px;
        }
    }
}