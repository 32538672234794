.you-might-be-interested-subtitle {
  @extend .subtitle;
  text-align: center;
  margin-top: 0;
  margin-bottom: 66px;
}

.you-might-be-interested-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @include desktop-s {
    flex-wrap: wrap;
  }
}
