@import "../scss/mixins";

@font-face {
  font-family: "Poppins-Regular";
  src: url("../assets/fonts/Poppins-Regular.otf");
}

.subtitle {
  color: $subtitleBlack;
  font-family: "Poppins-Regular";
  font-size: 45px;
  font-weight: 500;

  @include tablet {
    font-size: 35px;
  }

  @include mobile {
    font-size: 30px;
    font-weight: 600;
  }
}

.order-flow-title {
  color: $orderFlowTextBlack;
  font-family: "Poppins-Regular";
  font-size: 31px;
  font-weight: 600;
  margin-top: 120px;
  margin-bottom: 14px;
}

.order-flow-subtitle {
  color: $orderFlowTextBlack;
  font-family: "Poppins-Regular";
  font-size: 20px;
  font-weight: 400;
  margin-top: 0px;
}

.order-flow-text {
  color: $orderFlowTextBlack;
  font-family: "Poppins-Regular";
  font-size: 18px;
  font-weight: 600;
}

.order-flow-link {
  font-family: "Poppins-Regular";
  font-size: 18px;
  font-weight: 600;
  color: $red;
}

.input-text {
  color: black;
  font-family: "Poppins-Regular";
  font-size: 16px;
  font-weight: 400;
  opacity: 1;
}

.check-button-text {
  color: $checkButtonTextBlack;
  font-family: "Poppins-Regular";
  font-size: 13.5px;
  font-weight: 400;
}

.check-button-link {
  @extend .check-button-text;
  color: $red;
}

.knowledge-page-title {
  font-family: "Poppins-Regular";
  font-weight: 600;
  font-size: 40px;
  line-height: 150%;
  color: $white;
}

.knowledge-page-subtitle {
  font-family: "Poppins-Regular";
  font-weight: 300;
  font-size: 26px;
  line-height: 135%;
  color: $white;
}

.knowledge-page-date {
  font-family: "Poppins-Regular";
  font-weight: 300;
  font-size: 22px;
  line-height: 100%;
  color: $subtitleBlack;
}

.section-text {
  font-family: "Poppins-Regular";
  font-size: 25px;
  line-height: 176%;
  color: $subtitleBlack;
}

.recent-posts-title {
  font-family: "Poppins-Regular";
  font-weight: 600;
  font-size: 23px;
  line-height: 34px;
  margin-top: 0px;
  color: $subtitleBlack;
}

.article-with-desc-description {
  font-family: "Poppins-Regular";
  font-size: 20px;
  line-height: 155%;
  color: $subtitleBlack;
}

.article-with-desc-title {
  font-family: "Poppins-Regular";
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
  overflow: hidden;  
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $subtitleBlack;
}

.knowledge-page-title-single {
  font-family: "Poppins-Regular";
  font-weight: 600;
  font-size: 40px;
  line-height: 150%;
  color: $subtitleBlack;

  @include desktop-s {
    font-size: 35px;
  }

  @include tablet-s {
    font-size: 30px;
  }
}

.user-name-font {
  font-family: "Poppins-Regular";
  font-size: 19px;
  line-height: 28px;

  color: $navbarTextBlack;
}

.review-title {
  font-family: "Poppins-Regular";
  font-weight: 500;
  font-size: 23px;
  line-height: 38px;
  color: $subtitleBlack;  

  @include tablet {
    font-size: 18px;
    line-height: 28px;    
  }
}

.user-level-text-font {
  font-family: "Poppins-Regular";
  font-size: 17px;
  line-height: 35px;
  color: $subtitleBlack;
  text-transform: uppercase;
}

.order-details-title-font {
  font-family: "Poppins-Regular";
  font-size: 18px;
  line-height: 35px;
  color: $subtitleBlack;
  text-transform: uppercase;
}

.details-factory-name-font {
  font-family: "Poppins-Regular";
  font-weight: 600;
  font-size: 23px;
  line-height: 28px;
  color: $subtitleBlack;
}

.details-order-option-font {
  font-family: "Poppins-Regular";
  font-size: 19px;
  line-height: 28px;
  color: $subtitleBlack;
}

.review-names-flavours-font {
  font-family: "Poppins-Regular";
  font-size: 20px;
  line-height: 35px;
  max-width: 541px;
  word-wrap: break-word;
  color: $subtitleBlack;
}

.review-description-font {
  font-family: "Poppins-Regular";
  font-size: 21px;
  line-height: 36px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: $subtitleBlack;
}

.reward-title-font {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 33.5px;
  line-height: 50px;
  color: $orderFlowTextBlack;
}

.reward-points-font {
  font-family: "Poppins-Regular";
  font-weight: 600;
  font-size: 52px;
  line-height: 52px;
  color: $checkButtonTextBlack;
}

.reward-subtitle-font {
  font-family: "Poppins-Regular";
  font-weight: 600;
  font-size: 21.4px;
  line-height: 24px;
  color: $orderFlowTextBlack;
}

.profile-page-titles-font {
  font-family: "Poppins-Regular";
  font-weight: 600;
  font-size: 36px;
  line-height: 69px;
  color: $subtitleBlack;
}
