.review-container {  
  width: 348px;
  padding: 0px 22px 22px 22px;
  margin-bottom: 68px;
  box-sizing: border-box;  

  @include tablet-m {
    padding: 0px 0px 0px 0px;    
  }  

  @include tablet-s {
    width: 100%;
  }

  .review-image {
    width: 100%;
    height: 324px;
    border-radius: 15px;
  }

  .review-content-container {
    display: flex;
    flex-direction: column;
    padding: 33px;

    @include tablet-s {
      padding: 10px;
    }

    .stars-container {
      padding-top: 20px;
    }

    .stars-container-reverse {
      margin-bottom: 5px;
    }

    .reverse-order {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
