.knowledge-page {
  .title-container {
    display: flex;
    margin-bottom: 107px;

    background-image: linear-gradient(
        92.47deg,
        rgba(0, 0, 0, 0.0001) 6.54%,
        #000000 63.24%
      ),
      url("../../assets/images/knowledgePage/knowledge-page-header.png");

    min-height: 290px;

    background-repeat: no-repeat;
    background-size: cover;

    padding-top: 62px;
    padding-left: 82px;
    padding-right: 82px;

    justify-content: space-between;

    @include desktop-s {
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }

    @include desktop-m {
      padding-top: 40px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include tablet-m {
      display: block !important;
      margin-bottom: 57px;
    }

    &_logo-container {      
      margin-top: 60px; 
      
      @include tablet-m {
        margin-top: 0px;
      }
    }

    &_logo {
      width: 332px;
      height: 79px;

      @include tablet-m {
        width: 50%;
        height: 50%;
      }
    }
  }

  .knowledge-page-content {
    display: flex;
    flex-direction: row;    

    padding: 0 82px 82px 58px;

    justify-content: space-between;

    @include desktop-s {
      padding: 0 80px 82px 20px;
    }

    @include desktop-m {
      padding: 0 60px 82px 10px;
    }

    @include tablet {
      padding: 0 50px 82px 00px;
    }

    @include tablet-m {
      display: flex;
      flex-direction: column-reverse;
      padding-right: 0px;
    }

    .all-articles-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 100%;      
    }

    .search-filter-articles-container {
      display: flex;
      flex-direction: column;         
      
      @include tablet {
        width: 30%;
      }

      @include tablet-m {
        align-self: center;
      }

      #category-title {
        @extend .recent-posts-title;        
        margin-left: 20px;
      }

      #article-category-button {
        border: none;
        background-color: white;
        text-align: left;
        padding-left: 20px;        
        cursor: pointer;
        
        &_clicked {
          @extend #article-category-button;          
        }

        &_text {
          @extend .order-flow-subtitle;

          &_clicked {
            @extend .order-flow-subtitle;
            color: $red;
          }
        }
      }
    }
  }
}

.knowledge-page-title {
  color: $white !important;
}
