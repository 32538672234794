.card-payment-container {  
  display: flex;  
  flex-direction: column;  

  &_input-payment-card {
    @extend .input-text;
    height: 65px;
    margin-bottom: 16px;
    text-indent: 24px;
    border: 0.72px solid $inputBorderGray;
    border-radius: 6.3px;
    width: 463px;

    @include tablet-m {
      width: 262px;
    }
  }
}

.add-card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include tablet-m {    
    flex-direction: column;
    margin-bottom: 24px;
  }

  &_text {
    @extend .check-button-text;
  }

}

.add-card-container-part {
  display: flex;
  align-items: center;
  cursor: pointer;  
}

.oval-image {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

#credit-cards {
  border-radius: 4px;
}
