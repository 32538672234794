.order-info-text {
  font-family: "Poppins-Regular";
  font-weight: 500;
  font-size: 17.55px;
  display: flex;
  justify-content: space-between;
  padding-inline: 24px;

  &_text {
    width: 50%;

    @include desktop-m {
      overflow: hidden;  
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.delete-icon-container {
  display: flex;
  align-items: center;

  .delete-btn {
    border: none;
    width: 25px;
    height: 25px;
    background-color: $backgroundGray;
    margin-left: 32px;
    cursor: pointer;

    .delete-icon {
      height: 100%;
      mix-blend-mode: multiply;
      overflow: hidden;
      border: none;
    }
  }
}

.total-price-text {
  margin-right: 54px;
}
