.terms-and-conditions-page {
  padding-inline: 88px;

  &_title {
    @extend .subtitle;
    text-align: center;
  }

  &_text-container {
    margin-bottom: 88px;
  }
}
