// Colors
$backgroundWhite: #f2f2f2;
$gray: #686767;
$lightGray: #979797;
$borderGray: #a7a7a7;
$navbarTextBlack: #383838;
$red: #e23036;
$subtitleBlack: #353535;
$backgroundGray: #f3f3f3;
$orderFlowTextBlack: #313131;
$inputBorderGray: #9d9d9d;
$checkButtonTextBlack: #1a1919;
$footerBackgroundBlack: #171717;
$footerTitleGray: #d1d1d1;
$footerTextGray: #9e9e9e;
$white: #ffffff;
$container-gray: #f6f6f6;
$svgFillRed: #e23036;

// Responsive breakpoints
$desktop-xl: 1920px;
$desktop-m: 1440px;
$desktop-s: 1200px;
$tablet: 1024px;
$tablet-m: 768px;
$tablet-s: 568px;
$mobile: 414px;

// Dimensions
$logoWidths: (
  "dark": 200px,
  "white": 237px,
);

$logoHeights: (
  "dark": 50px,
  "white": 58px,
);

$headerImageHeight: 753px;
$headerImageTextWidth: 692px;

$coverImageHeight: 430px;

$primaryButtonWidths: (
  "small": 235px,
  "medium": 360px,
  "large": 457px,
);

$primaryButtonHeights: (
  "small": 69px,
  "large": 73px,
  "xSmall": 58px,
);

$inputFieldWidths: (
  "small": 171px,
  "medium": 271px,
  "large": 457px,
);
