.verify-mail {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;

  &_image {
    width: 250px;
    height: 250px;  

    @include tablet-s {
      width: 150px;
      height: 150px
    }
  }

  &_title {
    @extend .order-flow-title; 
    margin-top: 10px;

    @include tablet-s {
      font-size: 20px;
      width: 150px;
      text-align: center;
    }
  }
}