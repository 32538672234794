.header-background {
  background-image: url("../../../assets/images/landingPage/header_image.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: $headerImageHeight;

  @include tablet {
    height: 535px;
  }

  @include tablet-s {
    height: 466px;
  }

  &_text-container {
    width: $headerImageTextWidth;
    padding-left: 152px;

    @include tablet {
      padding-left: 94px;
      width: 60%;
    }

    @include tablet-s {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 30px;
    }

    @include mobile {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 0px;
    }
  }

  &_button-container {
    align-self: center;
    width: 188px;

    @include tablet-s {
      width: 235px;
    }
  }

  &_title {
    font-family: "Poppins-Regular";
    font-size: 63px;
    font-weight: 600;
    color: white;
    padding-top: 224px;
    margin: 0px;

    @include tablet {
      font-size: 50px;
      padding-top: 124px;
    }

    @include tablet-s {
      font-size: 40px;
      text-align: center;
      padding-top: 24px;
    }

    @include mobile {
      font-size: 40px;
      text-align: center;
    }
  }

  &_text {
    font-family: "Poppins-Regular";
    font-size: 22px;
    font-weight: 400;
    color: white;
    margin-bottom: 0px;

    @include tablet {
      font-size: 16px;
    }

    @include tablet-s {
      font-size: 14px;
      text-align: center;
      font-weight: 300;
    }

    @include mobile {
      font-size: 14px;
      text-align: center;
      font-weight: 300;
    }
  }
}
