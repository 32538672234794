.onboarding-coffee-flavours-container {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;

  @include desktop-s {
    width: 70%;
  }

  @include tablet {
    width: 80%;
  }

  @include tablet-s {
    width: 100%;
  }
}
