.product {
  box-sizing: border-box;
  width: 266px;
  margin-right: 34px;
  margin-bottom: 70px;  

  &_image {
    width: 100%;
    height: 340px;
    border-radius: 12px;
    object-fit: cover;
    cursor: pointer;    

    @include mobile {
      height: 206px;    
    }
  }

  @include desktop-s {
    margin-bottom: 0px;
  }

  @include tablet-m {    
    margin-right: 0px;
  }

  @include mobile {
    width: 206px;        
  }

  &_button-text {
    width: 196px;
    height: 34px;
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 21.6px;
    line-height: 32.4px;
    color: #353535;
    overflow: hidden;
    margin-bottom: 0px;
  
    @include desktop-s {
      width: 100%;    
    }
  
    @include tablet {
      text-align: center;    
    }  
  }

  &_button-price {
    height: 34px;
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 22.4px;
    line-height: 34px;
    color: #353535;
    margin-top: 0px;
    margin-bottom: 0px;  
  
    @include tablet {
      text-align: center;    
    }  
  }
}




