.navbar {
  width: 100%;
  margin: 0 auto;
  position: relative;  

  &_hidden {
    display: none;
  }

  &_header {
    height: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 90px;
    padding-right: 90px;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.12506);

    @include desktop-s {
      padding-left: 36px;
      padding-right: 36px;
    }

    @include tablet {
      padding-left: 28px;
      height: 97px;
    }

    @include mobile {
      height: 68px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &_logo {
    width: map-get($map: $logoWidths, $key: "dark");
    height: map-get($map: $logoHeights, $key: "dark");

    @include desktop-s {
      width: 174px;
      height: 42px;
    }

    @include mobile {
      width: 120px;
      height: 29px;
    }
  }

  &_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_links {
    display: flex;
    justify-content: space-between;
    width: 430px;

    @include desktop-s {
      width: 310px;
    }

    @include tablet-m {
      display: none;
    }
  }

  &_register {
    display: flex;
    justify-content: center;
    margin-inline-start: 44px;
    margin-inline-end: 44px;

    @include desktop-s {
      margin-inline-start: 22px;
      margin-inline-end: 22px;
    }

    @include tablet {
      margin-inline-start: 15px;
      margin-inline-end: 15px;
    }

    @include tablet-m {
      display: none;
    }
  }

  &_hamburger {
    display: none;
    width: 22px;
    height: 17px;
    @include tablet-m {
      display: block;

      cursor: pointer;
    }

    @include mobile {
      width: 18px;
      height: 13px;
    }
  }

  &_icon {
    width: 25px;
    height: 25px;
    margin-right: 26px;

    @include mobile {
      width: 17px;
      height: 17px;
    }
  }

  &__sidebar_position {
    position: fixed;
    width: 100%;
    z-index: 1000;
  }

  &__overlay {
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
  }

  &__sidebar {
    background-color: #fff;
    width: 330px;
    height: 100vh;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    right: -100%;
  }

  &__sidebar--active {
    @extend .navbar__sidebar;

    right: 0;
    position: absolute;
    z-index: 1000;
  }

  &__close_icon_container {
    border-bottom: 1px solid #000;
    width: 100%;
  }

  &__close {
    padding: 20px 0px 16px 20px;
    align-self: flex-start;
    cursor: pointer;
    height: 30px;
    width: 30px;
  }

  &__sidebar_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 66px;
    padding-top: 81px;
  }
}

.sidebar-enter {
  opacity: 0;
}
.sidebar-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.sidebar-exit {
  opacity: 1;
}
.sidebar-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.Dropdown-control {
  border: none !important;
  cursor: pointer;
}

.dropdown-image {
  width: 36px;
  height: auto;
  margin-left: 25px;
  border-radius: 5px;

  @include desktop-s {
    margin-left: 15px;
  }

  @include mobile {
    margin-left: 15px;
    width: 25px;
    height: auto;
  }
}
