.cash-payment {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  &_input-cash-payment {
    @extend .input-text;
    height: 65px;
    margin-bottom: 16px;
    text-indent: 24px;
    border: 0.72px solid $inputBorderGray;
    border-radius: 6.3px;
    width: 463px;

    @include tablet-m {
      width: 262px;
    }
  }

  @include tablet-m {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_text-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
