.interested-item-button {
  margin-right: 45px;
  width: 348px;
  background: transparent;
  border: none;
}

.interested-item-image-container {
  height: 425px;
  border-radius: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.interested-item-name {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 34.5px;
  color: $subtitleBlack;
  margin-bottom: 0px;
  overflow: hidden;
  height: 70px;
}

.interested-item-price {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  color: $subtitleBlack;
  margin-bottom: 0px;
  margin-top: 19px;
}
