.knowledge {
  box-sizing: border-box;

  .knowledge-padding-template {
    @include tablet {
      padding-left: 36px;
      padding-right: 36px;
    }

    @include mobile {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &-subtitle {
    @extend .subtitle;
    margin-top: 100px;
    margin-bottom: 100px;

    @include tablet {
      margin-top: 87px;
      margin-bottom: 56px;
    }

    @include mobile {
      margin-top: 61px;
      margin-bottom: 38px;
    }

    .knowledge-heading {
      font-size: 45px;
      margin: 0;
      @include tablet {
        font-size: 35px;
      }
    }

    .knowledge-paragraph {
      margin: 0;
      font-size: 22px;
      line-height: 33px;
      font-weight: 400;

      @include tablet {
        font-size: 19px;
        line-height: 29px;
      }

      @include mobile {
        font-size: 16px;
        line-height: 25px;
      }
    }
  }

  &-image-container {
    display: flex;
    justify-content: center;

    @include tablet-s {
      flex-wrap: wrap;
    }
  }

  &-categories {
    margin: auto;
    width: 50%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 70px;

    .categories-btn {
      width: 123px;
      height: 35px;
      font-family: "Poppins-Regular";
      font-size: 23px;
      line-height: 34.5px;
      font-weight: 400;
      border: none;
      background: transparent;
      color: #000000;

      &:hover {
        font-weight: 700;
      }
    }

    .first-btn {
      transform: translateX(40%);
    }

    .last-btn {
      transform: translateX(-40%);
    }
  }

  &-read-more {
    height: 617px;
    background-color: rgba($color: #000000, $alpha: 0.9);
    background-image: url("../../../assets/images/landingPage/Bitmap.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 150px;
    margin-bottom: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;

    @include tablet {
      margin-top: 132px;
      height: 466px;
      margin-bottom: 120px;
    }

    @include tablet-s {
      margin-top: 80px;
      padding-left: 58px;
      padding-right: 58px;
    }

    @include mobile {
      height: 791px;
      margin-top: 38px;
      padding-left: 40px;
      padding-right: 40px;
      margin-bottom: 55px;
    }

    .read-more-heading {
      margin-top: 0;
      margin-bottom: 30px;
      font-family: "Poppins-Regular";
      font-size: 45px;
      line-height: 67.5px;
      font-weight: 500;

      @include tablet {
        font-size: 35px;
        line-height: 40px;
      }

      @include mobile {
        font-size: 40px;
        line-height: 60px;
        font-weight: 600;
      }
    }

    .read-more-subtitle {
      margin: 0;
      font-family: "Poppins-Regular";
      font-size: 20px;
      line-height: 40px;
      font-weight: normal;

      @include tablet {
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
}
