.order-fast-subtitle {
  @extend .subtitle;
  margin-top: 87px;
  margin-bottom: 49px;

  @include tablet {
    margin-top: 76px;
  }

  @include mobile {
    margin-top: 45px;
  }
}

.order-fast-category {
  display: flex;
  justify-content: center;

  @include tablet-s {
   flex-wrap: wrap;
  }
}
