.checkbox-button-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 36px;
}

.checkbox-button-container-login-page {
  @extend .checkbox-button-container;
  justify-content: space-between;

  &_first-part {
    display: flex;
    align-items: center;
  }
}

.checkbox-button-container-order-flow {
  @extend .checkbox-button-container;
  justify-content: left;  
  margin-top: 36px;

  @include tablet-m {
    justify-content: center;
  }
}

.check-button {
  height: 24px;
  border: none;
  background-color: white;
  margin-right: 6px;
  cursor: pointer;

  &_image-pressed {
    height: 100%;
    width: 100%;
  }

  &_text {
    @extend .check-button-text;
  }

  &_link {
    @extend .check-button-link;
  }

  &_link-button {
    @extend .check-button-link;
    background-color: $white;
    border: none;
    padding: 0px;
    cursor: pointer;
  }
}
