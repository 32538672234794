.ordering-options-button-container {
  @include tablet-m {
    display: flex;
    flex-direction: column;
    align-items: center;    
  }
}

.single-product-button {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 220px;
  height: 61px;
  border: 1px solid #adadad;
  border-radius: 10px;
  font-family: "Poppins-Regular";
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #1f1f1f;
  background-color: white;
  cursor: pointer;  

  &_clicked {
    @extend .single-product-button;
    border: 1px solid $red;
  }
}
