.confirm-payment-page {
  display: flex;
  flex-direction: column;
  align-items: center;


  .confirm-payment-page_container {
    display: flex;
    justify-content: space-evenly;

    &-card {
      margin-right: 47px;
    }
  }

  &_text {
    @extend .check-button-text;
    margin-left: 6px;
    align-items: center;

    @include tablet-m {
      text-align: center;
      width: auto;
      margin-left: 6px;
    }
  }

  &_button {
    margin-top: 41px;
    margin-bottom: 41px;
  }
}

@media screen and (max-width: 900px) {
  .confirm-payment-page_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}