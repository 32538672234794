.popular-choice-button {
  display: grid;
  height: 434px;
  width: 348px;
  background-color: $backgroundGray;
  border: none;
  margin-inline: 22px;
  padding-left: 0px;
  padding-right: 0px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;

  @include tablet {
    height: 329px;
    width: 264px;
  }

  @include tablet-m {
    margin-bottom: 25px;
  }

  @include tablet-s {
    width: 100%;
    height: 100%;
    margin-inline: 0px;
  }

  &_image-background {
    width: 100%;
    height: 310px;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;

    @include tablet {
      height: 240px;
      background-position: center;      
    }
  }

  &_text-container {
    padding-left: 28px;
    padding-right: 28px;
    text-align: left;

    @include tablet {
      padding-left: 20px;
    }
  }

  &_name {
    font-family: "Poppins-Regular";
    font-size: 25px;
    font-weight: 500;
    margin-top: 24px;

    @include tablet {
      font-size: 18px;
      margin-top: 14px;
    }
  }

  &_volume {
    font-family: "Poppins-Regular";
    font-size: 17px;
    font-weight: 500;
    margin-top: 0px;

    @include tablet {
      font-size: 14px;
      font-weight: 300;
    }
  }
}
