.input-field {
  @extend .input-text;
  height: 65px;
  margin-bottom: 16px;
  text-indent: 24px;
  border: 0.72px solid $inputBorderGray;
  border-radius: 6.3px;

  &_email-error {
    @extend .order-details-title-font;
    margin-top: 0;
    align-self: flex-start;
    text-align: left;
    color: $red;
    text-transform: capitalize;
  }
}

.input-field::placeholder {
  @extend .input-text;
}

.input-field-small {
  @extend .input-field;
  width: map-get($map: $inputFieldWidths, $key: "small");
}

.input-field-medium {
  @extend .input-field;
  width: map-get($map: $inputFieldWidths, $key: "medium");
}

.input-field-large {
  @extend .input-field;
  width: map-get($map: $inputFieldWidths, $key: "large");

  @include tablet-s {
    width: 400px;
  }

  @include mobile {
    width: 321px;
  }
}
