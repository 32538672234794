.order-history-bar-in-process {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1.2px solid #80bfff;
  border-radius: 9px;
  box-sizing: border-box;
  padding: 56px 42px 60px 48px;
  margin: 14px 0 14px 0;

  &_non-logged-user {
    @extend .order-history-bar-in-process;
    background-color: white;
    padding: 10px;
    margin: 0;
    margin-bottom: 10px;
  }

  .top-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    font-family: "Poppins-Regular";
    font-size: 20px;
    line-height: 30px;
    color: #353535;

    &_non-logged-user {
      @extend .top-row;
      font-size: 14px;

      @include tablet {
        font-size: 12px;
      }

      @include tablet-m {
        font-size: 10px;
      }
    }

    &_attribute-list {
      display: flex;
      flex-direction: column;
    }

    &_span-left {
      text-align: left;
    }

    &_span-right {
      text-align: right;
    }

    @include mobile {
      flex-direction: column;
    }
  }

  .order-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Poppins-Regular";
    font-weight: 500;
    font-size: 30px;
    line-height: 34px;
    color: #353535;
    text-transform: uppercase;

    &_non-logged-user {
      @extend .order-item;
      font-size: 14px;
      line-height: 24px;

      @include tablet {
        font-size: 12px;
      }

      @include tablet-m {
        font-size: 10px;
      }
    }

    @include mobile {
      flex-direction: column;
    }

    .product-name {
      text-transform: none;

      &_first-part {
        @extend .product-name;
        width: 60%;
        text-align: left;
      }

      @include tablet {
        font-size: 16px;
      }

      @include mobile {
        text-align: center;
        width: 100%;
      }
    }
  }
}

.order-history-bar-accepted {
  @extend .order-history-bar-in-process;
  border: 1.2px solid #00cc66;
}

.order-history-bar-rejected {
  @extend .order-history-bar-in-process;
  border: 1.2px solid $red;
}

.order-history-text {
  margin-top: 0;
  font-family: "Poppins-Regular";
  color: #353535;

  @include tablet {
    font-size: 12px;
  }

  @include tablet-m {
    font-size: 10px;
  }
}

.order_header_wrapper {
  display: flex;
  justify-content: space-between;
}

.order_close_icon {
  cursor: pointer;
  margin-top: 0,
}
