.button {
  background-color: $red;
  overflow: hidden;
  cursor: pointer;
  border: none;
  border-radius: 9px;
  color: white;
  font-family: "Poppins-Regular";
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 56px;
}

.primary-button-small {
  @extend .button;
  height: map-get($map: $primaryButtonHeights, $key: "small");
  width: map-get($map: $primaryButtonWidths, $key: "small");
}

.primary-button-medium {
  @extend .button;
  height: map-get($map: $primaryButtonHeights, $key: "large");
  width: map-get($map: $primaryButtonWidths, $key: "medium");
  margin-top: 79px;

  @include tablet {
    margin-top: 60px;
    width: map-get($map: $primaryButtonWidths, $key: "small");
    height: map-get($map: $primaryButtonHeights, $key: "xSmall");
    font-size: 15px;
    letter-spacing: 1px;
  }

  
}

.primary-button-large {
  @extend .button;
  height: map-get($map: $primaryButtonHeights, $key: "small");
  width: map-get($map: $primaryButtonWidths, $key: "large");
  margin: auto;

  @include tablet-s {
    width: 400px;
    height: map-get($map: $primaryButtonHeights, $key: "xSmall");
  }

  @include mobile {
    width: 321px;
    height: map-get($map: $primaryButtonHeights, $key: "xSmall");
  }
}
