.payment-method-button {
  width: 351px;
  height: 115px;
  cursor: pointer;
  border: 0.72px solid #9d9d9d;
  border-radius: 6.3px;
  background-color: white;
  margin-bottom: 10px;

  @include tablet {
    width: 262px;
  }

  @include tablet-s {
    width: 212px;
  }
}

.main-container {
  display: grid;
  margin-top: 73px;
  justify-items: center;
}

.payment-method-button-clicked {
  @extend .payment-method-button;
  border: 0.72px solid red;
}

.payment-text {
  color: $checkButtonTextBlack;
  font-family: "Poppins-Regular";
  font-size: 13.5px;
  font-weight: 400;
}

.input-payment-card {
  @extend .input-text;
  height: 65px;
  margin-bottom: 16px;
  text-indent: 24px;
  border: 0.72px solid $inputBorderGray;
  border-radius: 6.3px;

  @include tablet-m {
    width: 262px;
  }

  &_city-input {
    width: 256px;

    @extend .input-payment-card;
    margin-right: 15px;

    @include tablet {
      margin-right: 0px;
    }
  }

  &_post-code-input {
    @extend .input-payment-card;
    width: 178px;

    @include tablet-m {
      width: 262px;
    }
  }
}

.card-info-title {
  @extend .input-text;
  width: 300px;
  height: 23px;
  font-size: 15px;
  line-height: 22px;
  text-align: left;
  text-transform: uppercase;
  color: #313131;

  @include tablet {
    width: 262px;
  }
}
