.error-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 204px;
    margin-bottom: 170px;

    &_subtitle {
        @extend .order-flow-subtitle;
        margin-bottom: 0px;
        margin-top: 23px;        

        @include tablet {
            text-align: center
        }
    }    
}