.login-page {
  display: grid;
  justify-content: center;
  text-align: center;

  &_main-container {
    margin-bottom: 100px;
  }

  &_input-container {
    display: grid;
    margin-top: 73px;
  }
}
