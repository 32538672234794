.onboarding-question {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.onboarding-question-heading {
    text-align: center;

    .onboarding-question-title {
        @extend .order-flow-title;
    }

    .onboarding-question-subtitle {
        @extend .order-flow-subtitle;
        margin-bottom: 50px;
    }
}

.first-question-counter {
    display: flex;
    align-items: center;

    .first-question-minus-sign {
        width: 50px;
        border: none;
        background-color: transparent;
    
        img {
            width: 100%;
        }
    }
    
    .first-question-plus-sign {
        width: 50px;
        border: none;
        background-color: transparent;
    
        img {
            width: 100%;
        }
    }

    .first-question-counter {
        width: 50px;
        display: flex;
        justify-content: center;
        font-family: "Poppins-Regular";
        font-size: 50px;
        line-height: 50px;
        color: $red;
        margin: 0 20px;
    }

}

.skip-button {
    font-family: "Poppins-Regular";
    font-size: 20px;
    line-height: 16px;
    color: #434343;
    margin-top: 50px;
    margin-bottom: 120px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

