.floating-user-container {
  position: absolute;
  left: 4.15%;
  right: 5.39%;
  top: 30%;
  bottom: 34.53%;
  display: flex;
  max-width: 270px;
  text-align: center;
  flex-direction: column;

  @include mobile {
    left: 16%;
  }

  .coffeebeans-amount-font {
    font-family: "Poppins-Regular";
    font-size: 21px;
    line-height: 33px;
    color: #353535;
  }

  .images-container {
    .image {
      border-radius: 50%;
    }

    .profile-image {
      width: 218px;
      height: 218px;
    }

    .user-level-image {
      position: relative;
      bottom: 30%;
      left: 65%;
      width: 67px;
      height: 67px;

      .inner-svg {
        bottom: 15%;
        left: 35%;
        width: 42px;
        height: 42px;
      }
    }

    .edit-image {
      position: relative;
      max-width: 31px;
      max-height: 31px;
      left: 80%;
      bottom: 90%;

      .upload-btn {
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    margin-top: -80px;
  }
}
