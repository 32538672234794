.user-data {
  display: flex;
  align-items: center;

  img {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin-right: 14px;
  }

  .text-data {
    display: flex;
    flex-direction: column;

    .user-name-font {
      margin-top: 0px;
      font-family: "Poppins-Regular";
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: $navbarTextBlack;
    }
  }
}
