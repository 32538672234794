.size-button-gray {
  margin-right: 10px;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background: #d8d8d8;
  border: none;
  color: #353535;
  font-size: 15px;
  line-height: 23px;

  @include desktop-s {
    margin-bottom: 8px;
  }
}

.size-button-red {
  @extend .size-button-gray;
  background: #e23036;
  color: #ffffff;

  
}
