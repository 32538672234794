@import "./variables.scss";

@mixin desktop-xl {
  @media (max-width: #{$desktop-xl}) {
    @content;
  }
}

@mixin desktop-m {
  @media (max-width: #{$desktop-m}) {
    @content;
  }
}

@mixin desktop-s {
  @media (max-width: #{$desktop-s}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet}) {
    @content;
  }
}

@mixin tablet-m {
  @media (max-width: #{$tablet-m}) {
    @content;
  }
}

@mixin tablet-s {
  @media (max-width: #{$tablet-s}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile}) {
    @content;
  }
}
