.shipping-info-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  @include tablet-m {
    justify-items: center;
  }

  &_title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_edit-button {
    background-color: $white;
    border: none;
    cursor: pointer;
  }

  &_input-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    @include tablet-m {
      display: grid;
    }
  }
}
