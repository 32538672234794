.container {
  display: grid;
  margin-bottom: 223px;
  margin-inline: 153px;

  @include tablet {
    margin-inline: 0px;
  }
}

.title-container {
  text-align: center;
}

.payment-buttons-container {
  display: flex;

  &_bank-card {
    margin-right: 40px;

    @include tablet {
      margin-right: 0px;
    }
  }

  @include tablet {
    flex-direction: column;
    align-items: center;
  }
}

.add-card-title {
  font-family: "Poppins-Regular";
  font-size: 15.3px;
  line-height: 22.95x;
  color: #838383;
}

.guest-title {
  height: 36px;
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 50px;
  color: #313131;

  @include desktop-s {
    height: auto;
  }

  @include tablet {
    text-align: center;
  }
}

.guest-subtitle {
  height: 22px;
  top: 491px;
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  text-transform: uppercase;
  color: #313131;
  padding-bottom: 50px;

  @include tablet {
    text-align: center;
  }
}

.payment-card-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  @include tablet-s {
    flex-direction: column;
    align-items: center;
  }
}

.ordering-options-container {
  display: flex;
  flex-direction: column;

  @include tablet-m {
    justify-items: center;
    align-items: center;
  }
}

.order-checkout-main-container {
  display: grid;
  justify-content: center;  

  .order-checkout-container {
    display: flex;
    flex-direction: row;
    margin-top: 73px;    

    &_large-input {
      @extend .input-text;
      height: 65px;
      margin-bottom: 16px;
      text-indent: 24px;
      border: 0.72px solid $inputBorderGray;
      border-radius: 6.3px;

      @include tablet-m {
        width: 262px;
      }
    }

    @include tablet {
      display: block;
      padding-top: 0px;
      margin-bottom: 50px;
      margin-top: 23px;
    }

    .about-you-container {
      justify-content: center;
      margin-bottom: 183px;
      margin-right: 47px;
      width: 463px;

      .location-container {
        display: grid;
        margin-top: 30px;

        @include tablet-m {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      @include tablet {
        margin-bottom: 30px;
        width: 100%;
      }

      .payment-type-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .bio-container {
        display: grid;

        @include tablet-m {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .bio-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}

.modal-buttons-container {
  @include tablet-s {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
