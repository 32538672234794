.profile-page-container {
  background-color: #FCFCFC;

  .cover-container {
    display: flex;
    justify-content: flex-end;
    background-color: black;
    background-size: cover;
    width: 100%;
    height: 300px;
    background-position: center;

    button {
      margin-right: 170px;

      @include tablet-m {
        margin-right: 20px;
      }
    }
  }

  .user-level-icons-container {
    margin-bottom: 165px;
    height: 120px;
    background-color: $white;
    display: flex;
    justify-content: flex-end;
    padding-right: 155px;
    align-items: center;

    @include tablet-m {
      padding-right: 32px;
    }

    &_img {
      height: 43px;
      width: 43px;
      margin-left: 38.5px;

      @include tablet-m {
        margin-left: 18.5px;
      }

      @include mobile {
        display: none;
      }
    }
  }

  .user-name-font {
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
  }

  .profile-data {
    .progress-overview-section {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0 155px 55px 155px;

      .profile-page-titles-font {
        margin-bottom: 40px;
      }

      @include desktop-s {
        padding: 0 125px 25px 125px;
      }

      @include tablet-m {
        padding: 0 25px 5px 25px;
      }

      @include tablet-s {
        padding: 0 15px 5px 15px;
      }
    }

    .dashboard-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      width: 100%;
      padding: 0 155px 55px 155px;
      box-sizing: border-box;

      @include desktop-s {
        padding: 0 25px 0px 25px;
      }
    }
  }
}

.change-cover-btn {
  background-color: transparent;
  border: 1.08px solid #dcdcdc;
  border-radius: 8.1px;
  font-family: "Poppins-Regular";
  font-size: 13.5px;
  color: #dcdcdc;
  width: 171px;
  height: 42.3px;
  margin-top: 47px;
  cursor: pointer;

  @include tablet {
    width: 120px;
    height: 24px;
    font-size: 9px;
  }

  &:hover {
    border: 2px solid $gray;
  }
}
