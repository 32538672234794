.article {
  box-sizing: border-box;
  width: 348px;
  text-align: left;
  margin-inline: 17px;

  @include tablet {
    border-radius: 10px;
    margin-bottom: 40px;
    width: 265px;
  }

  @include tablet-m {
    border-radius: 10px;
    margin-bottom: 40px;
    width: 165px;
  }

  @include tablet-s {
    padding: 0px;
    width: 100%;
  }

  &-image-knowledge {
    width: 100%;
    height: 324px;
    border-radius: 15px;

    @include tablet {
      border-radius: 10px;
    }
  }

  &-image-community {
    width: 100%;
    border-radius: 15px;

    @include tablet {
      border-radius: 10px;
    }
  }

  &-button {
    width: 165px;
    cursor: pointer;
    height: 50px;
    border-radius: 5px;
    border: 1px solid black;
    background: transparent;
    font-family: "Poppins-Regular";
    font-size: 20px;
    line-height: 30px;

    @include tablet {
      width: 145px;
      height: 43px;
      font-size: 14px;
      line-height: 21px;
    }
  }

  &-text {
    font-family: "Poppins-Regular";
    font-size: 23px;
    line-height: 34px;
    font-weight: 400;    

    @include tablet {
      font-size: 18px;
      line-height: 28px;
    }
  }
}
