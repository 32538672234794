#modal-button-container {
  display: flex;
  align-items: center;
  margin-top: 34px;
  margin-bottom: 18px;
  font-family: "Poppins-Regular";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #555555;
  border: none;
  background-color: transparent;
  position: relative;
  cursor: pointer;

  @include tablet-s {
    font-size: 16px;
  }
}

#modal-button-container::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 52px;
  left: 10%;
  border-bottom: 2px solid #a2a2a2;
}

.icon-location {
  width: 19px;
  height: 22.94px;
  margin-right: 20px;
}

.icon-time {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}
