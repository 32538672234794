.community {
  box-sizing: border-box;
  margin-bottom: 150px;
  margin-top: 67px;

  @include tablet {
    margin-bottom: 100px;
  }

  @include mobile {
    margin-bottom: 50px;
  }

  .community-padding-template {
    @include tablet {
      padding-left: 36px;
      padding-right: 36px;
    }

    @include mobile {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &-subtitle {
    @extend .subtitle;
    margin-bottom: 100px;

    @include tablet {
      margin-bottom: 63px;
    }

    @include mobile {
      margin-bottom: 50px;
    }

    .community-heading {
      font-size: 45px;
      margin: 0;

      @include tablet {
        font-size: 35px;
      }

      @include mobile {
        font-size: 30px;
        font-weight: 600;
      }
    }

    .community-paragraph {
      margin: 0;
      font-size: 22px;
      line-height: 33px;
      font-weight: 400;

      @include tablet {
        display: none;
      }
    }
  }

  &-image-container {
    display: flex;
    justify-content: center;

    @include tablet-s {
      flex-wrap: wrap;
    }
  }

  .horizontal-line {
    width: 60%;
    border-bottom: 1.5px solid #979797;
    margin: 0 auto;
  }
}
