.check-out-container {
  display: flex;
  margin-top: 102px;
  margin-bottom: 31px;

  @include tablet {
    flex-direction: column;
    align-items: center;
  }

  &_email-input {
    @extend .input-text;
    height: 65px;
    margin-bottom: 16px;
    text-indent: 24px;
    border: 0.72px solid $inputBorderGray;
    border-radius: 6.3px;
    width: 351px;

    @include desktop-m {
      width: 262px;
    }

    @include desktop-s {
      width: 212px;
    }

    @include tablet {
      width: 351px;
    }

    @include tablet-m {
      width: 262px;
    }

    @include tablet-s {
      width: 212px;
    }
  }
}

.sign-in-container {
  flex: 1;

  @include desktop-m {
    width: 262px;
  }

  @include desktop-s {
    width: 212px;
  }

  @include tablet {
    width: 351px;
  }

  @include tablet-m {
    width: 262px;
  }

  @include tablet-s {
    width: 212px;
  }
}

.order-summary-container {
  flex: 1;

  @include desktop-m {
    width: 262px;
  }

  @include desktop-s {
    width: 212px;
  }

  @include tablet {
    width: 351px;
  }

  @include tablet-m {
    width: 262px;
  }

  @include tablet-s {
    width: 212px;
  }
}

.button-order-checkout {
  background-color: $red;
  overflow: hidden;
  cursor: pointer;
  border: none;
  border-radius: 9px;
  color: white;
  font-family: "Poppins-Regular";
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 56px;
  height: 69px;
  width: 351px;
  margin: auto;

  @include desktop-m {
    width: 262px;
  }

  @include desktop-s {
    width: 212px;
  }

  @include tablet {
    width: 351px;
  }

  @include tablet-m {
    width: 262px;
  }

  @include tablet-s {
    width: 212px;
  }
}

.button-order-checkout-white {
  @extend .button-order-checkout;
  background-color: white;
  color: #313131;
}

.order-details-block {
  width: 350px;
  margin-top: 23px;
  display: inline-block;
  background: #f3f3f3;
  border-radius: 5.95px;

  @include desktop-m {
    width: 262px;
  }

  @include desktop-s {
    width: 212px;
  }

  @include tablet {
    width: 351px;
  }

  @include tablet-m {
    width: 262px;
  }

  @include tablet-s {
    width: 212px;
  }
}

.order-details-block-checkout {
  @extend .order-details-block;
  width: 457px;
  margin-top: 0px;

  @include tablet-s {
    width: 351px;
  }

  @include mobile {
    width: 262px;
  }
}

.email-error {
  @extend .order-details-title-font;
  color: $red;
  text-transform: capitalize;
}
