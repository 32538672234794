.footer-logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 31px;
    gap: 10px;

    &_icon {
        height: 40px;
        width: 45px;

        @include tablet-s {
            height: 25px;
        }

        @include mobile {
            height: 15px;
        }
    }
    &_icon_intesa {
        height: 40px;
        width: 180px;

        @include tablet-s {
            height: 25px;
        }

        @include mobile {
            height: 15px;
        }
    }

    &_icon_secure {
        height: 40px;
        width: 65px;

        @include tablet-s {
            height: 45px;
        }

        @include mobile {
            height: 25px;
        }
    }

    &_link {
        cursor: pointer;
    }
}
.amex-logo{
    height: 27px;
}
