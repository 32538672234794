.addCardPageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.paymentFormContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;    
    margin-bottom: 24px;
}