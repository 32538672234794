@mixin navbar-button {
  font-family: "Poppins-Regular";
  font-size: 19px;
  font-weight: 400;
  background-color: white;
  color: $navbarTextBlack;
  cursor: pointer;

  @include desktop-s {
    font-size: 16px;
  }
}

.navbar-button {
  @include navbar-button();
  border: none;
}

.navbar-button:hover {
  @extend .navbar-button;
  font-weight: 600;
  color: black;
}

.navbar-button:focus {
  @extend .navbar-button;
  font-weight: 600;
  color: black;
}

.navbar-registration-button {
  @include navbar-button();
  border-radius: 9px;
  border-width: 1px;
  border-color: $borderGray;
  padding-inline: 32px;
  padding-block: 14px;

  @include desktop-s {
    padding-inline: 10px;
    padding-block: 5px;
  }
}

.navbar-registration-button:hover {
  @extend .navbar-registration-button;
  font-weight: 600;
  color: black;
}
