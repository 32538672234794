.title {
  font-family: "Poppins-Regular";
  font-size: 22px;
  font-weight: 500;
  color: $footerTitleGray;
  margin-top: 0px;
  margin-bottom: 16px;
}

.text {
  font-family: "Poppins-Regular";
  font-size: 18px;
  font-weight: 400;
  color: $footerTextGray;
  margin-top: 0px;
  margin-bottom: 16px;

  @include desktop-s {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 300;
  }
}

.footer {
  width: 100%;
  height: 496px;
  background-color: $footerBackgroundBlack;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_hidden {
    display: none;
  }

  @include tablet {
    height: 463px;
  }

  @include tablet-m {
    height: 900px;
  }

  &_top_container {
    display: flex;
    justify-content: space-between;

    @include tablet-m {
      flex-direction: column;
    }
  }

  &_logo {
    width: map-get($map: $logoWidths, $key: "white");
    height: map-get($map: $logoHeights, $key: "white");
    padding-top: 76px;
    padding-left: 88px;

    @include tablet {
      padding-left: 36px;
      padding-top: 60px;
      max-width: 100%;
      max-height: 100%;
      width: 154px;
      height: 38px;
    }

    @include mobile {
      padding-left: 27px;
      padding-top: 51px;
      max-width: 100%;
      max-height: 100%;
      width: 224px;
      height: 55px;
    }
  }

  &_visit_us_text_container {
    display: flex;
    padding-top: 76px;
    width: 100%;
    padding-left: 14%;

    @include tablet {
      padding-left: 36px;
      padding-top: 60px;
      width: 90%;
    }

    @include tablet-m {
      padding-top: 40px;
      flex-direction: column-reverse;
    }

    @include mobile {
      padding-top: 36px;
      padding-left: 27px;
    }
  }

  &_category_container {
    flex: 1;
    padding-right: 20px;

    @include tablet-m {
      margin-bottom: 42px;
    }
  }

  &_social_media {
    display: flex;
    width: 172px;
    justify-content: space-between;

    @include tablet {
      width: 150px;
    }
  }

  &_bottom {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 31px;
    align-items: center;

    @include tablet {
      justify-content: flex-end;
      width: 90%;
    }

    @include tablet-m {
      justify-content: flex-start;
      padding-left: 35px;
      flex-direction: column;
    }

    @include mobile {
      padding-left: 27px;
    }
  }

  &_email-link {    
    text-decoration: underline;
    cursor: pointer;
    color: $footerTextGray;
  }

  &_title {
    @extend .title;
  }

  &_text {
    @extend .text;
  }

  &_text-small {
    @extend .text;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 0px;
    padding-right: 100px;

    @include desktop-s {
      font-size: 12px;
      padding-right: 40px;
    }

    @include tablet {
      font-size: 12px;
      padding-right: 10px;
    }
  }

  &_img {
    width: 25px;
    height: 25px;
    max-width: 100%;
    max-height: 100%;
  }

  &_terms-and-conditions-button {
    background-color: $footerBackgroundBlack;
    border: none;
    text-decoration: underline;
    margin-top: 16px;
    cursor: pointer;
    color: $footerTextGray;
    padding: 0px;
  }
}
