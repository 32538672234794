.allCategories {
  &_subtitle {
    @extend .subtitle;
    margin-top: 131px;
    margin-bottom: 67px;
    text-align: center;

    @include tablet {
      margin-top: 86px;
    }

    @include mobile {
      margin-top: 60px;
    }
  }

  &_wrapper {
    display: grid;
    grid-template-columns: 543px 543px;
    row-gap: 16px;
    column-gap: 16px;
    justify-content: center;
    .category-button {
      justify-self: center;
    }

    @include desktop-s {
      grid-template-columns: 47% 47%;
    }

    @include tablet-s {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
